import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Aos from 'aos';
import 'aos/dist/aos.css';

const OurServices = () => {
    useEffect(() => {
        Aos.init({
            duration: 1200,
            offset: 200,
            once: false,
        });
    }, []);

    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state

    const getAllServices = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_KEY}/services/getAllServiceByBranchId/674586c19aa6780fb268867d`,
            headers: {},
        };

        axios
            .request(config)
            .then((response) => {
                console.log(response.data);
                setServices(response.data);
                setLoading(false); // Set loading to false after data is fetched
            })
            .catch((error) => {
                console.log(error);
                setLoading(false); // Set loading to false if there's an error
            }).finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getAllServices();
    }, []);

    // Function to handle the WhatsApp link creation
    const handleBookNow = (service) => {
        const phoneNumber = '918114882165'; // Replace with your phone number including country code
        const message = `Hello, I would like to book the service: ${service?.name}. Price: ₹ ${service?.price} for ${service?.duration} minutes.`;

        // URL encode the message to make sure it's formatted correctly
        const encodedMessage = encodeURIComponent(message);

        // Open WhatsApp with pre-filled message
        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

        // Open the WhatsApp chat
        window.open(whatsappUrl, '_blank');
    };

    return (
        <div className="bg-white text-gray-800 mx-6 md:mx-16">
            <div className="text-center py-12">
                <h2 className="text-[#4E2728] text-lg">Our Services</h2>
                <h1 style={{ fontFamily: 'Cinzel, serif' }} className="text-4xl font-bold mt-2">
                    Services For You
                </h1>
                <p className="text-gray-600 mt-4 max-w-2xl mx-auto">
                    Spas Can Be An Indulgent Luxury Or Part Of A Regular Wellness Routine, Depending On Personal Preference And Health Goals.
                </p>
            </div>
            <div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
                    {loading ? (
                        Array(4).fill(0).map((_, index) => (
                            <div key={index} className="text-center transition-transform duration-300 transform hover:scale-105 shadow-xl rounded-md border">
                                <div className="w-full h-48 bg-gray-300 rounded-md animate-pulse"></div>
                                <div className='flex flex-col px-3 '>
                                    <h3 className="mt-4 h-6 bg-gray-300 rounded animate-pulse"></h3>
                                    <p className="my-4 h-40 bg-gray-300 rounded animate-pulse"></p>
                                    <div className='flex justify-center'>
                                        <button className="px-8 py-4 bg-[#4E2728] text-white font-bold rounded my-4 opacity-50 cursor-not-allowed"></button>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        // Render actual services after data is loaded
                        services?.map((service, index) => (
                            <div
                                key={index}
                                data-aos={service?.animation}
                                className="text-center transition-transform duration-300 transform hover:scale-105 shadow-xl rounded-md border flex flex-col"
                            >
                                <img src={service?.image} alt={service?.name} className="w-full h-48 object-cover rounded-md" />

                                {/* Service Name */}
                                <h3 className="text-xl font-bold my-4 px-8">{service?.name}</h3>

                                {/* Service Description */}
                                <p className="text-gray-600 my-4 px-8">{service?.description}</p>

                                {/* Price and Duration */}

                                <div className="flex-grow" />

                                <div className="px-8 mb-4">
                                    <p className="text-lg font-semibold text-[#4E2728]">
                                        Price: ₹{service?.price} <span className="text-sm text-gray-500">/{service?.duration} min</span>
                                    </p>
                                </div>
                                {/* Book Now Button */}
                                <div>
                                    <button
                                        onClick={() => handleBookNow(service)}
                                        className="px-6 py-2 bg-[#4E2728] text-white font-bold rounded my-4 hover:bg-[#aa585a]"
                                    >
                                        Book Now
                                    </button>
                                </div>
                            </div>

                        ))
                    )}
                </div>
            </div>
        </div>
    );
};

export default OurServices;
