import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import HeroSection from '../../Components/home/HeroSection'
import OurCategories from '../../Components/home/OurCategories'
import OurServices from '../../Components/home/OurServices'
import GetTouch from '../../Components/home/GetTouch'
import GallerySection from '../../Components/home/GallerySection'
import Testimonial from '../../Components/home/Testimonial'
import OurTeam from '../../Components/home/OurTeam'
import BestPlans from '../../Components/aboutus/BestPlans'
import Aos from 'aos'
import 'aos/dist/aos.css'

const Home = () => {
  useEffect(() => {
    Aos.init({
      duration: 1200,
      offset: 200,
      once: true,
    });

  }, [])
  return (
    <div className='overflow-x-hidden'>
      <Helmet>
        {/* SEO Tags */}
        <title>Kaira Spa - Ultimate Relaxation Spa in Bhubaneswar</title>
        <meta
          name="description"
          content="Kaira Spa offers a range of luxurious spa services to rejuvenate and relax your mind and body. Book your appointment today for a refreshing experience."
        />
        <meta
          name="keywords"
          content="Spa, Wellness, Massage, Beauty, Relaxation, Aromatherapy, Skin Care, Facial, Body Treatments, Kaira Spa, Bhubaneswar"
        />

        {/* Open Graph Tags */}
        <meta property="og:title" content="Kaira Spa - Ultimate Relaxation Spa in Bhubaneswar" />
        <meta
          property="og:description"
          content="Kaira Spa offers a range of luxurious spa services to rejuvenate and relax your mind and body. Book your appointment today for a refreshing experience."
        />
        <meta
          property="og:image"
          content="https://www.kairaspa.com/assets/spa-banner.png"
        />
        <meta property="og:url" content="https://www.kairaspa.com" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@KairaSpa" />
        <meta name="twitter:title" content="Kaira Spa - Ultimate Relaxation Spa in Bhubaneswar" />
        <meta
          name="twitter:description"
          content="Kaira Spa offers a range of luxurious spa services to rejuvenate and relax your mind and body. Book your appointment today for a refreshing experience."
        />
        <meta
          name="twitter:image"
          content="https://www.kairaspa.com/assets/spa-banner.png"
        />

        {/* Canonical Tag */}
        <link rel="canonical" href="https://www.kairaspa.com" />
      </Helmet>
      <HeroSection />
      <OurCategories />
      <OurServices />
      <BestPlans />
      <GallerySection />
      <Testimonial />
      <OurTeam />
      <GetTouch />
    </div>
  )
}

export default Home