import React, { useState } from 'react';
import img1 from '../../Assets/homeimg/address 1.png';
import img2 from '../../Assets/homeimg/Email.png';
import img3 from '../../Assets/homeimg/Phone.png';

const GetTouch = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_KEY}/email-service/sendEmail`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ managerEmail: "kairaspa2021@gmail.com", ...formData }),
            });

            const data = await response.json();

            if (data.success) {
                setSuccess(true);
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    message: '',
                });
            } else {
                alert('Failed to send the message. Please try again later.');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('Failed to send the message. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="mx-auto py-12">
            <h1 style={{ fontFamily: 'Cinzel, serif' }} className="text-4xl font-bold text-center text-[#4E2728]">
                Contact <span className="text-black">Us</span>
            </h1>
            <p className="text-center text-gray-600 mt-4 mx-6">
                We'd Love To Hear From You! Whether You're Looking To Book An Appointment Or Have Questions About Our
                Services, Feel Free To Reach Out To Us.
            </p>

            <div className="flex flex-col lg:flex-row mt-12 md:mx-16">
                <div className="lg:w-1/3 bg-[#4E2728] p-8 text-white mb-8 lg:mb-0 rounded-md">
                    <div className="mb-8">
                        <img src={img1} alt="Map showing location of Kaira Spa at Hotel Sandy Tower, Bhubaneswar" />
                        <p className="mt-4">
                            Hotel Sandy Tower, XIMB Square, Nandankanan Rd, Gajapati Nagar, Bhubaneswar, Odisha 751013
                        </p>
                    </div>
                    <div className="mb-8">
                        <img src={img2} alt="Email address for Kaira Spa: kaliraspa2021@gmail.com" />
                        <p className="mt-4">Kaliraspa2021@Gmail.Com</p>
                    </div>
                    <div className="mb-8">
                        <img src={img3} alt="Phone number for Kaira Spa: +91 8114882165" />
                        <p className="mt-4">+91 8114882165</p>
                    </div>
                    <div>
                        <h2 className="text-xl font-bold">OPENING HOURS</h2>
                        <p className="mt-4">Monday- Sunday</p>
                        <p>9:00 - 10:00 PM</p>
                    </div>
                </div>

                <div className="lg:w-2/3 bg-yellow-100 p-8">
                    <h2 className="text-2xl font-bold mb-8">Get In Touch</h2>
                    {success && <p className="text-green-500 mb-4">Your message has been sent successfully!</p>}
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block mb-2" htmlFor="name">
                                <i className="fas fa-user"></i> Name
                            </label>
                            <input
                                className="w-full p-2 border border-gray-300 rounded"
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2" htmlFor="email">
                                <i className="fas fa-envelope"></i> Email
                            </label>
                            <input
                                className="w-full p-2 border border-gray-300 rounded"
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2" htmlFor="phone">
                                <i className="fas fa-phone"></i> Phone
                            </label>
                            <input
                                className="w-full p-2 border border-gray-300 rounded"
                                type="text"
                                id="phone"
                                name="phone"
                                placeholder="Phone"
                                value={formData.phone}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2" htmlFor="message">
                                <i className="fas fa-comment"></i> Message
                            </label>
                            <textarea
                                className="w-full p-2 border border-gray-300 rounded"
                                id="message"
                                name="message"
                                placeholder="Message"
                                value={formData.message}
                                onChange={handleChange}
                            ></textarea>
                        </div>
                        <button
                            className="bg-[#4E2728] text-white px-4 py-2 rounded"
                            type="submit"
                            disabled={loading}
                        >
                            {loading ? 'Submitting...' : 'Submit'}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default GetTouch;
