import React, { useEffect, useState } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';

const OurOffers = () => {
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Aos.init({
      duration: 1200,
      offset: 200,
      once: true,
    });
  }, [offers]);

  const getAllOffers = () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/offer/getAllOffersByBranchId/674586c19aa6780fb268867d`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setOffers(response.data);
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch((error) => {
        console.log(error);
        setLoading(false); // Set loading to false if there's an error
      }).finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllOffers();
  }, []);

  return (
    <div className="md:mx-16 mx-6 mt-10">
      <h1 className="text-3xl font-bold text-center mb-2">Our Offers</h1>
      <p className="text-center text-gray-600 mb-8">
        We Seek To Build A Lasting Relationship With You Based On Excellence, Integrity, And Forward-Thinking. FG
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {loading ? (
          // Skeleton loader while data is loading
          Array(2)
            .fill(0)
            .map((_, index) => (
              <div
                key={index}
                className="p-4 rounded-lg shadow-xl bg-white/400 animate-pulse"
              >
                <div className="bg-gray-300 rounded-lg mb-4 w-full h-60"></div>
                <div className="w-32 h-6 bg-gray-300 mb-2"></div>
                <div className="w-full h-4 bg-gray-300 mb-4"></div>
              </div>
            ))
        ) : (
          // Render actual offers after data is loaded
          offers?.map((offer) => (
            <div
              key={offer?.id}
              className="p-4 rounded-lg shadow-xl"
              data-aos={offer?.animation}
            >
              <img
                src={offer?.image}
                alt={offer?.description}
                className="w-full rounded-lg mb-4"
              />
              <h2 className="text-xl font-bold mb-2">{offer?.name}</h2>
              <p className="text-gray-600">{offer?.description}</p>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default OurOffers;
