// import React, { useRef, useState } from 'react';
// import { IoClose } from "react-icons/io5";
// import emailjs from 'emailjs-com';
// import img from '../../Assets/homeimg/girl-showing.jpg';

// const BookModal = ({ onClose }) => {
//     const modalRef = useRef();

//     const closeModal = (e) => {
//         if (modalRef.current === e.target) {
//             onClose();
//         }
//     };

//     const [formData, setFormData] = useState({
//         name: '',
//         email: '',
//         phone: '',
//         message: ''
//     });

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({
//             ...formData,
//             [name]: value
//         });
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();

//         emailjs.send('service_xyvcc1e', 'template_voinpgp', formData, 'z7shN8aJoJopttBxt')
//             .then((response) => {
//                 console.log('SUCCESS!', response.status, response.text);
//                 onClose(); 
//             }, (err) => {
//                 console.log('FAILED...', err);
//             });
//     };

//     return (
//         <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50" ref={modalRef} onClick={closeModal}>
//             <div className="bg-white rounded-lg shadow-lg p-6 flex flex-col md:flex-row w-full max-w-4xl">
//                 {/* Image section, hidden on small screens, visible on md and up */}
//                 <div className="w-full md:w-1/2 md:block hidden">
//                     <img src={"https://img.freepik.com/free-vector/flat-design-illustration-customer-support_23-2148887720.jpg?ga=GA1.1.1594279737.1741935701&semt=ais_authors_boost"} alt="Image" className="w-full h-full object-cover rounded-lg" />
//                 </div>
//                 {/* Form section */}
//                 <div className="w-full md:w-1/2 md:pl-6 mt-6 md:mt-0">
//                     <div>
//                         <IoClose size={23} className="float-right mb-5 cursor-pointer" onClick={onClose} />
//                     </div>
//                     <form className="space-y-4" onSubmit={handleSubmit}>
//                         <input
//                             type="text"
//                             name="name"
//                             placeholder="Name"
//                             value={formData.name}
//                             onChange={handleChange}
//                             className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300"
//                         />
//                         <input
//                             type="email"
//                             name="email"
//                             placeholder="Email address"
//                             value={formData.email}
//                             onChange={handleChange}
//                             className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300"
//                         />
//                         <input
//                             type="tel"
//                             name="phone"
//                             placeholder="Phone"
//                             value={formData.phone}
//                             onChange={handleChange}
//                             className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300"
//                         />
//                         <textarea
//                             name="message"
//                             placeholder="Message"
//                             value={formData.message}
//                             onChange={handleChange}
//                             className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300 h-32"
//                         ></textarea>
//                         <button
//                             type="submit"
//                             className="w-full bg-[#FFC700] text-white p-3 rounded-lg"
//                         >
//                             Send Message
//                         </button>
//                     </form>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default BookModal;


import React, { useRef, useState } from 'react';
import { IoClose } from "react-icons/io5";  // Importing close icon
import axios from 'axios';  // Importing Axios to make the API call

const BookModal = ({ onClose }) => {
    const modalRef = useRef();

    const closeModal = (e) => {
        if (modalRef.current === e.target) {
            onClose();
        }
    };

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);  // Set loading state

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_KEY}/email-service/sendEmail`, {
                managerEmail: "kairaspa2021@gmail.com",  // Manager email
                ...formData  // Spread form data
            });

            if (response.data.success) {
                setSuccess(true);  // Set success message if email was sent
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    message: ''
                });
            } else {
                alert('Failed to send the message. Please try again later.');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('Failed to send the message. Please try again later.');
        } finally {
            setLoading(false);  // Set loading state to false
        }
    };

    return (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50" ref={modalRef} onClick={closeModal}>
            <div className="bg-white rounded-lg shadow-lg p-6 flex flex-col md:flex-row w-full max-w-4xl">
                {/* Image section, hidden on small screens, visible on md and up */}
                <div className="w-full md:w-1/2 md:block hidden">
                    <img src={"https://img.freepik.com/free-vector/flat-design-illustration-customer-support_23-2148887720.jpg?ga=GA1.1.1594279737.1741935701&semt=ais_authors_boost"} alt="Image" className="w-full h-full object-cover rounded-lg" />
                </div>
                {/* Form section */}
                <div className="w-full md:w-1/2 md:pl-6 mt-6 md:mt-0">
                    <div>
                        <IoClose size={23} className="float-right mb-5 cursor-pointer" onClick={onClose} />
                    </div>
                    <form className="space-y-4" onSubmit={handleSubmit}>
                        {success && <p className="text-green-500 mb-4">Your message has been sent successfully!</p>}
                        <input
                            type="text"
                            name="name"
                            placeholder="Name"
                            value={formData.name}
                            onChange={handleChange}
                            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300"
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder="Email address"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300"
                        />
                        <input
                            type="tel"
                            name="phone"
                            placeholder="Phone"
                            value={formData.phone}
                            onChange={handleChange}
                            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300"
                        />
                        <textarea
                            name="message"
                            placeholder="Message"
                            value={formData.message}
                            onChange={handleChange}
                            className="w-full p-3 border border-gray-300 text-black rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300 h-32"
                        ></textarea>
                        <button
                            type="submit"
                            className="w-full bg-[#FFC700] text-white p-3 rounded-lg"
                            disabled={loading} // Disable button when loading
                        >
                            {loading ? 'Submitting...' : 'Send Message'}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default BookModal;
