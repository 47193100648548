import React from 'react'

const YourBeauty = () => {
  const handleBookNow = () => {
    const phoneNumber = '918114882165';
    const message = `Hello, I would like to book an Appointment.`;

    const encodedMessage = encodeURIComponent(message);

    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

    window.open(whatsappUrl, '_blank');
  };
  return (
    <div class=" mx-16 py-8">
      <div class="flex flex-col md:flex-row items-center">
        <div class="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
          <img alt="A person receiving a relaxing massage in a serene environment" class="w-full md:w-1/2 rounded-lg shadow-lg" height="400" src="https://storage.googleapis.com/a1aa/image/wC09FyfBhv3RO6WMPnK7CKZeOWy66DCnrtSgX6HJdMqGJBlTA.jpg" width="300" />
          <img alt="A person receiving a facial treatment with a brush" class="w-full md:w-1/2 rounded-lg shadow-lg" height="400" src="https://storage.googleapis.com/a1aa/image/HHeIVI8ncDXwRaQVK2mQfbGbxxt5q9edgfT09X3zegskIJocC.jpg" width="300" />
        </div>
        <div class="mt-8 md:mt-0 md:ml-8 text-center md:text-left">
          <h2 class="text-yellow-500 text-lg font-bold">
            About Us
          </h2>
          <h1 class="text-4xl font-playfair-display font-bold mt-2">
            Your Beauty And Serenity Starts Here
          </h1>
          <p class="text-gray-600 mt-4 font-roboto">
            A "Spa" Is A Facility Or Establishment That Provides Therapeutic And Relaxation Services To Enhance Physical And Mental Well-Being. The Concept Of A Spa Can Range From Simple Health And Beauty Treatments To More Elaborate Wellness Experiences. Here's A Breakdown Of Common Types Of Spas And Services
          </p>
          <button
            onClick={() => handleBookNow()}
            class="mt-6 bg-[#4E2728] text-white font-bold py-2 px-6 rounded-lg shadow-lg hover:bg-yellow-600">
            Book Now
          </button>
        </div>
      </div>
    </div>
  )
}

export default YourBeauty