import React from 'react'

const Map = () => {
    return (
        <React.Fragment>
            <div>
                <div className="w-full h-80 lg:h-[500px] mt-10">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3741.828122314803!2d85.81840977501089!3d20.307380281167536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a1909903d88a573%3A0x47c275bc0a698ae4!2sKaira%20spa!5e0!3m2!1sen!2sin!4v1734338953309!5m2!1sen!2sin"
                        width="100%"
                        height="100%" 
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default Map