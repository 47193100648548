import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaUserCircle } from "react-icons/fa"; // Optional: Use an icon as a placeholder

const OurTeam = () => {
  const [team, setTeam] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state for skeleton loader

  // Fetch team data
  const showTeam = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/team/getTeamByBranchId/674586c19aa6780fb268867d`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Response: ", response.data);
        setTeam(response?.data);
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch((error) => {
        console.log(error);
        setLoading(false); // Set loading to false if there's an error
      }).finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    showTeam();
  }, []);

  return (
    <div className="text-center py-12 relative">
      <h2 className="text-[#4E2728] text-lg font-roboto">Our Team</h2>
      <h1
        className="text-4xl font-playfair-display font-bold mt-2 mx-6"
        style={{ fontFamily: "Cinzel, serif" }}
      >
        Relax, You’re In Good Hands
      </h1>
      <p className="text-gray-500 mt-4 mx-6">
        Spas Can Be An Indulgent Luxury Or Part Of A Regular Wellness Routine,
        <br />
        Depending On Personal Preference And Health Goals.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 mt-5 mx-6 md:mx-16">
        {loading ? (
          // Skeleton loader for team members while loading data
          Array(4).fill(0).map((_, index) => (
            <div
              key={index}
              className="bg-gradient-to-b from-[#4E2728] to-transparent rounded-lg shadow-lg p-4 animate-pulse"
            >
              <div className="bg-gray-300 rounded-lg w-full h-[20rem] mb-4"></div>
              <div className="w-32 h-6 bg-gray-300 mb-4"></div>
              <div className="w-24 h-4 bg-gray-300 mb-2"></div>
            </div>
          ))
        ) : (
          // Render actual team members after data is loaded
          team.map((member, index) => (
            <div
              key={index}
              className="bg-gradient-to-b from-[#4E2728] to-transparent rounded-lg shadow-lg p-4 transition-transform duration-300 transform hover:scale-105"
            >
              <img
                src={member?.profilePic}
                alt={`Portrait of ${member?.name}`}
                className="rounded-lg mb-4 w-full h-[20rem] object-cover"
              />
              <h3 className="text-xl font-playfair-display font-bold">
                {member?.name}
              </h3>
              <p className="text-gray-500">{member?.role}</p>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default OurTeam;
