import React from 'react';
import { Helmet } from 'react-helmet';
import SimpleBanner from '../Common/SimpleBanner';
import AboutUs_banner from '../../Assets/about/about.png';
import OurServices from '../../Components/home/OurServices';
import GetTouch from '../../Components/home/GetTouch';

const Services = () => {
  return (
    <div className='overflow-x-hidden'>
      <Helmet>
        {/* SEO Tags */}
        <title>Our Services - Kaira Spa</title>
        <meta 
          name="description" 
          content="Explore the luxurious spa services offered by Kaira Spa. From massages to skincare treatments, discover our premium services for ultimate relaxation." 
        />
        <meta 
          name="keywords" 
          content="Spa Services, Massage, Wellness, Relaxation, Aromatherapy, Skin Care, Facial, Body Treatments, Kaira Spa, Bhubaneswar" 
        />

        {/* Open Graph Tags */}
        <meta property="og:title" content="Our Services - Kaira Spa" />
        <meta 
          property="og:description" 
          content="Explore the luxurious spa services offered by Kaira Spa. From massages to skincare treatments, discover our premium services for ultimate relaxation." 
        />
        <meta 
          property="og:image" 
          content="https://www.kairaspa.com/assets/spa-banner.png" 
        />
        <meta property="og:url" content="https://www.kairaspa.com/services" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@KairaSpa" />
        <meta name="twitter:title" content="Our Services - Kaira Spa" />
        <meta 
          name="twitter:description" 
          content="Explore the luxurious spa services offered by Kaira Spa. From massages to skincare treatments, discover our premium services for ultimate relaxation." 
        />
        <meta 
          name="twitter:image" 
          content="https://www.kairaspa.com/assets/spa-banner.png" 
        />

        {/* Canonical Tag */}
        <link rel="canonical" href="https://www.kairaspa.com/services" />
      </Helmet>

      {/* Page Content */}
      <SimpleBanner
        image={AboutUs_banner}
        name="Our Services"
        path="Home / Our Services"
      />
      <OurServices />
      <GetTouch />
    </div>
  );
};

export default Services;
