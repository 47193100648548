import React from 'react';
import { Helmet } from 'react-helmet';  // Import React Helmet for SEO
import SimpleBanner from '../../Screens/Common/SimpleBanner';
import AboutUs_banner from '../../Assets/about/about.png';
import YourBeauty from '../../Components/aboutus/YourBeauty';
import BestPlans from '../../Components/aboutus/BestPlans';
import OurServices from '../../Components/home/OurServices';

const About = () => {
  return (
    <div className=''>
      <Helmet>
        {/* SEO Tags */}
        <title>About Us - Kaira Spa</title>
        <meta 
          name="description" 
          content="Learn more about Kaira Spa in Bhubaneswar, offering a range of luxurious spa services to rejuvenate your mind and body. Experience wellness and relaxation with our premium services." 
        />
        <meta 
          name="keywords" 
          content="Kaira Spa, About Us, Spa, Wellness, Massage, Relaxation, Beauty Treatments, Skincare, Bhubaneswar, Wellness Spa" 
        />

        {/* Open Graph Tags */}
        <meta property="og:title" content="About Us - Kaira Spa" />
        <meta 
          property="og:description" 
          content="Learn more about Kaira Spa in Bhubaneswar, offering a range of luxurious spa services to rejuvenate your mind and body." 
        />
        <meta 
          property="og:image" 
          content="https://www.kairaspa.com/assets/spa-banner.png" 
        />
        <meta property="og:url" content="https://www.kairaspa.com/about" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Us - Kaira Spa" />
        <meta 
          name="twitter:description" 
          content="Discover the story of Kaira Spa, offering luxurious services for complete relaxation and wellness." 
        />
        <meta 
          name="twitter:image" 
          content="https://www.kairaspa.com/assets/spa-banner.png" 
        />

        {/* Canonical Tag */}
        <link rel="canonical" href="https://www.kairaspa.com/about" />
      </Helmet>

      {/* Page Content */}
      <SimpleBanner
        image={AboutUs_banner}
        name="About Spa Magic"
        path="Home / About us"
      />
      <YourBeauty />
      <OurServices />
      <BestPlans />
    </div>
  );
};

export default About;
