import React, { useState, useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import axios from "axios";

const GallerySection = () => {
  const [allGallery, setAllGallery] = useState([]);
  const [loading, setLoading] = useState(true);

  const showAllgalleryData = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/gallery/getGallery/674586c19aa6780fb268867d`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setAllGallery(response.data.data);
        setLoading(false); 
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      }).finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    showAllgalleryData();
  }, []);

  useEffect(() => {
    Aos.init({
      duration: 1200,
      offset: 200,
      once: false,
    });
  }, []);

  const getRandomImages = () => {
    if (allGallery?.length > 6) {
      const shuffled = [...allGallery]?.sort(() => Math.random() - 0.5);
      return shuffled.slice(0, 6);
    }
    return allGallery;
  };

  const randomImages = getRandomImages();

  return (
    <>
      <div className="text-center mb-8">
        <p className="text-[#4E2728]">Gallery</p>
        <h1 style={{ fontFamily: "Cinzel, serif" }} className="text-3xl font-bold">
          Our Latest Works
        </h1>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10 mx-6 md:mx-16">
        {loading ? (
          Array(6).fill(0).map((_, index) => (
            <div
              key={index}
              className="h-64 w-full bg-gray-300 rounded-[1rem] animate-pulse"
            ></div>
          ))
        ) : (
          randomImages?.map((image, index) => (
            <img
              key={index}
              src={image?.gallery}
              alt={`Image of ${image?.serviceName} at Kaira Spa branch, showcasing our luxurious spa treatments and services`}
              data-aos={index % 2 === 0 ? "fade-right" : "fade-left"}
              className="rounded-[1rem] object-cover h-full w-full"
            />
          ))
        )}
      </div>
    </>
  );
};

export default GallerySection;
