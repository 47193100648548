import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';  // Import React Helmet for SEO
import SimpleBanner from '../../Screens/Common/SimpleBanner';
import AboutUs_banner from '../../Assets/about/about.png';
import OurOffers from '../../Components/Offers/OurOffers';
import GetTouch from '../../Components/home/GetTouch';
import Aos from 'aos';
import 'aos/dist/aos.css';

const Offers = () => {
  useEffect(() => {
    Aos.init({
      duration: 1200,  
      offset: 200,    
      once: true,
    });
  }, []);

  return (
    <div className='overflow-x-hidden'>
      <Helmet>
        {/* SEO Tags */}
        <title>Special Offers - Kaira Spa</title>
        <meta 
          name="description" 
          content="Explore the best spa offers and discounts at Kaira Spa in Bhubaneswar. Enjoy luxurious treatments and rejuvenate with exclusive offers." 
        />
        <meta 
          name="keywords" 
          content="Spa Offers, Discounts, Kaira Spa, Wellness Offers, Massage Deals, Bhubaneswar Spa, Spa Treatments, Beauty Offers" 
        />

        {/* Open Graph Tags */}
        <meta property="og:title" content="Special Offers - Kaira Spa" />
        <meta 
          property="og:description" 
          content="Check out the latest spa offers at Kaira Spa. Get rejuvenating massages and beauty treatments with special discounts and deals." 
        />
        <meta 
          property="og:image" 
          content="https://www.kairaspa.com/assets/spa-banner.png" 
        />
        <meta property="og:url" content="https://www.kairaspa.com/offers" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Special Offers - Kaira Spa" />
        <meta name="twitter:description" content="Find the latest spa offers and special discounts at Kaira Spa. Rejuvenate with top-class wellness services at great prices." />
        <meta 
          name="twitter:image" 
          content="https://www.kairaspa.com/assets/spa-banner.png" 
        />

        {/* Canonical Tag */}
        <link rel="canonical" href="https://www.kairaspa.com/offers" />
      </Helmet>

      {/* Page Content */}
      <SimpleBanner
        image={AboutUs_banner}
        name="Offers"
        path="Home / Offers"
      />
      <OurOffers />
      <GetTouch />
    </div>
  );
};

export default Offers;
