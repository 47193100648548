import React from 'react';
import { Helmet } from 'react-helmet';  // Import React Helmet for SEO
import SimpleBanner from '../../Screens/Common/SimpleBanner';
import AboutUs_banner from '../../Assets/about/about.png';
import Map from '../../Components/ContactUs/Map';
import GetTouch from '../../Components/home/GetTouch';

const Contact = () => {
  return (
    <div>
      <Helmet>
        {/* SEO Tags */}
        <title>Contact Us - Kaira Spa</title>
        <meta 
          name="description" 
          content="Get in touch with Kaira Spa in Bhubaneswar for luxurious spa services. Contact us for booking appointments, inquiries, and more." 
        />
        <meta 
          name="keywords" 
          content="Kaira Spa, Contact Us, Spa Booking, Wellness, Massage, Beauty Treatments, Contact Kaira Spa, Bhubaneswar Spa" 
        />

        {/* Open Graph Tags */}
        <meta property="og:title" content="Contact Us - Kaira Spa" />
        <meta 
          property="og:description" 
          content="Contact Kaira Spa in Bhubaneswar for luxurious spa services, inquiries, and bookings. Get in touch with us today." 
        />
        <meta 
          property="og:image" 
          content="https://www.kairaspa.com/assets/spa-banner.png" 
        />
        <meta property="og:url" content="https://www.kairaspa.com/contact" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact Us - Kaira Spa" />
        <meta name="twitter:description" content="Reach out to Kaira Spa for inquiries, bookings, or more information on our luxurious spa services." />
        <meta 
          name="twitter:image" 
          content="https://www.kairaspa.com/assets/spa-banner.png" 
        />

        {/* Canonical Tag */}
        <link rel="canonical" href="https://www.kairaspa.com/contact" />
      </Helmet>

      {/* Page Content */}
      <SimpleBanner
        image={AboutUs_banner}
        name="Contact"
        path="Home / Contact"
      />
      <GetTouch />
      <Map />
    </div>
  );
};

export default Contact;
