import React from "react";
import { Helmet } from "react-helmet";

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <Helmet>
        <title>404 - Page Not Found | Kaira Spa</title>
        <meta
          name="description"
          content="Oops! The page you're looking for could not be found on Kaira Spa website. Return to the home page or explore our services."
        />
        <meta name="robots" content="noindex, nofollow" />
        {/* Open Graph Tags for Social Media */}
        <meta property="og:title" content="404 - Page Not Found | Kaira Spa" />
        <meta
          property="og:description"
          content="Oops! The page you're looking for could not be found on Kaira Spa website. Return to the home page or explore our services."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.kairaspa.com/404" />
        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="404 - Page Not Found | Kaira Spa" />
        <meta
          name="twitter:description"
          content="Oops! The page you're looking for could not be found on Kaira Spa website. Return to the home page or explore our services."
        />
      </Helmet>

      <h1 className="text-4xl font-bold" >404 Not Found</h1>
      <p className="mt-4">The page you are looking for does not exist.</p>
    </div>
  );
};

export default NotFound;
