import React, { useEffect, useState } from "react";
import axios from "axios";
import BookModal from "./BookModal";

const HeroSection = () => {
  const [open, setOpen] = useState(false);
  const [heroSectionData, setHeroSectionData] = useState();
  const [loading, setLoading] = useState(true); // Track overall loading status
  const [bgLoading, setBgLoading] = useState(true); // Track background image loading

  // Fetch Hero Section Data
  const showHeroSection = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/heroSection/getHeroSection/674586c19aa6780fb268867d`,
      headers: {},
    };

    setLoading(true);
    axios
      .request(config)
      .then((response) => {
        setHeroSectionData(response.data.data);
        setLoading(false); // Data is fetched
      })
      .catch((error) => {
        console.log(error);
        setLoading(false); // Even if there is an error, stop loading
      });
  };

  const handleAddOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  // Run fetch data when component mounts
  useEffect(() => {
    showHeroSection();
  }, []);

  return (
    <React.Fragment>
      {/* Loading Skeleton */}
      {loading  ? (
        <div className="hidden md:block md:py-10 h-[85vh] text-center bg-gray-300 animate-pulse">
          <div className="text-start text-black p-8 max-w-7xl z-10 md:w-1/2 md:space-y-8">
            <div className="h-8 w-3/4 bg-gray-400 mb-2"></div>
            <div className="h-8 w-3/4 bg-gray-400 mb-2"></div>
            <div className="h-6 w-1/2 bg-gray-400 mb-6"></div>
            <div className="h-6 w-1/2 bg-gray-400 mb-6"></div>
            <div className="w-32 h-12 bg-[#4E2728] rounded-md mb-4"></div>
          </div>
        </div>
      ) : (
        // Actual Hero Section Content
        <div
          className="hidden md:block md:py-10 h-[85vh] text-center"
          style={{
            backgroundImage: `url(${heroSectionData?.[0]?.bgImg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right",
          }}
          onLoad={()=>setBgLoading(false)}
        >
          <div className="text-start text-black p-8 max-w-7xl z-10 md:w-1/2 md:space-y-12">
            <h1
              style={{ fontFamily: "Cinzel, serif" }}
              className="text-3xl md:text-7xl mb-4 tracking-wider text-center lg:text-start md:mt-0 mt-10 font-bold md:font-medium"
            >
              {heroSectionData?.[0]?.heading}
            </h1>
            <p className="text-base md:text-lg mb-6 tracking-wide text-center lg:text-start font-bold md:font-normal">
              {heroSectionData?.[0]?.subheading}
              <br />
              The Art Of Beauty.
            </p>
            <button
              className="mt-4 bg-[#4E2728] text-white font-bold py-2 px-4 rounded shadow hover:bg-[#aa585a]"
              onClick={handleAddOpen}
            >
              Book Now
            </button>
          </div>
        </div>
      )}

      {/* Mobile Version */}
      {loading  ? (
        <div className="block md:hidden md:py-10 h-screen text-center bg-gray-300 animate-pulse">
          <div className="text-start text-black p-8 max-w-7xl z-10 md:w-1/2 md:space-y-12">
            <div className="h-8 w-3/4 bg-gray-400 mb-4"></div>
            <div className="h-6 w-1/2 bg-gray-400 mb-6"></div>
            <div className="w-32 h-12 bg-[#FFC700] rounded-full mb-4"></div>
          </div>
        </div>
      ) : (
        <div
          className="block md:hidden md:py-10 h-screen text-center"
          style={{
            backgroundImage: `url(${heroSectionData?.[0]?.bgImg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right",
          }}
          // onLoad={()=>setBgLoading(false)}
        >
          <div className="text-start text-black p-8 max-w-7xl z-10 md:w-1/2 md:space-y-12">
            <h1
              style={{ fontFamily: "Cinzel, serif" }}
              className="text-3xl md:text-7xl mb-4 tracking-wider text-center lg:text-start md:mt-0 mt-10 font-bold md:font-medium"
            >
              Best Place for Natural SPA Treatment
            </h1>
            <p className="text-base md:text-lg mb-6 tracking-wide text-center lg:text-start font-bold md:font-normal">
              Discover Your Signature Style With Our Expert Team: Personalized
              Beauty Journeys In A Luxurious Haven - Where Innovation Meets
              Tradition In
              <br />
              The Art Of Beauty.
            </p>
            <div className="flex justify-center">
              <button
                className="bg-[#FFC700] text-white font-bold py-2 px-6 rounded-full shadow-lg hover:bg-[#aa585a]"
                onClick={handleAddOpen}
              >
                Book Now
              </button>
            </div>
          </div>
        </div>
      )}

      {open && <BookModal onClose={handleModalClose} />}
    </React.Fragment>
  );
};

export default HeroSection;
